import { Box, ChakraProps, Flex, HStack, Text, colors } from "@biblioteksentralen/react";
import { MouseEventHandler } from "react";
import { useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { usePatron } from "../../minside/dataFetchers/usePatron";
import ConfirmModal from "../../minside/layout/ConfirmModal";

export const HeaderUserInfo: React.FunctionComponent<ChakraProps> = (props) => {
  const { t } = useTranslation();
  const { rediaPlatform } = useRediaPlatformContext();
  const patron = usePatron();
  const user = patron.data?.user;

  const onLogoutClick: MouseEventHandler = () => {
    if (!rediaPlatform) return;
    rediaPlatform.logout();
  };

  if (!user) return null;

  return (
    <Box fontSize="xs" {...props}>
      <HStack alignItems="center">
        <Box fontWeight="600">{user.givenName ? `${user.givenName} ${user.surname}` : user.fullName}</Box>
        <Box marginTop="0.1rem" height="0.8rem" borderLeft="solid 0.1rem" borderColor={colors.grey15}></Box>
        <ConfirmModal
          confirmButtonText={t("Logg ut")}
          abortButtonText="Avbryt"
          variant="plain"
          fontSize="0.7rem"
          fontWeight="normal"
          size="sm"
          height="inherit"
          _hover={{ textDecor: "underline" }}
          textDecor="unset"
          onClick={onLogoutClick}
          bodyText={
            <Flex flexDir="column" alignItems="center">
              <Text>{t("Er du sikker på at du vil logge ut?")} </Text>
            </Flex>
          }
        >
          {t("Logg ut")}
        </ConfirmModal>
      </HStack>
    </Box>
  );
};
