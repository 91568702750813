import { z } from "zod";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useMultipleUrlStates } from "../../../utils/hooks/useMultipleUrlStates/useMultipleUrlStates";
import { ModalContainer } from "../../ModalContainer";
import { Button, Flex, Text } from "@biblioteksentralen/react";
import { Info } from "react-feather";
import { logoutConfirmationParameter, useRediaPlatformContext } from "../../../rediaPlatform/RediaPlatformProvider";
import { Suspense, useEffect } from "react";

export const ShowLogoutConfirmationModal = () => {
  return (
    <Suspense>
      <ShowLogoutConfirmationModalContent />
    </Suspense>
  );
};

const ShowLogoutConfirmationModalContent = () => {
  const { t } = useTranslation();
  const logoutConfirmationStateSchema = z.object({ [logoutConfirmationParameter]: z.boolean().optional() });
  const [logoutConfirmationState, setLogoutConfirmationState] = useMultipleUrlStates(logoutConfirmationStateSchema);
  const { rediaPlatform } = useRediaPlatformContext();

  const showConfirmation = !!logoutConfirmationState?.[logoutConfirmationParameter];
  const isLoggedIn = !!rediaPlatform?.getUser();

  //unset logout query param if logged in
  useEffect(() => {
    showConfirmation && isLoggedIn && setLogoutConfirmationState({ [logoutConfirmationParameter]: undefined });
  }, [showConfirmation, isLoggedIn, setLogoutConfirmationState]);

  return (
    <ModalContainer
      isOpen={logoutConfirmationState?.[logoutConfirmationParameter] ?? false}
      onClose={() => setLogoutConfirmationState({ [logoutConfirmationParameter]: undefined })}
      size="xs"
      isCentered
      footer={
        <Button
          size="sm"
          onClick={() => setLogoutConfirmationState({ [logoutConfirmationParameter]: undefined })}
          width="5rem"
        >
          {t("Lukk")}
        </Button>
      }
    >
      <Flex flexDir="column" alignItems="center">
        <Info size="2rem" />
      </Flex>
      <Flex flexDir="column" alignItems="center">
        <Text paddingTop="1rem">{t("Du har blitt logget ut.")}</Text>
      </Flex>
    </ModalContainer>
  );
};
