import { Box, Collapse, HStack, Heading, Icon, Link, colors } from "@biblioteksentralen/react";
import { getIsInsideAnIframe } from "@libry-content/common";
import { logAdminClick } from "../../../utils/analytics/Plausible";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { HeaderFooterContainer } from "../../ContentContainer";
import { useEditInSanityContext } from "../../editInSanity/EditInSanityContext";
import { EditPencil } from "../../editInSanity/EditPencil";
import { usePreviewContext } from "../../preview/previewContext";
import { useSanityAuth } from "../../sanityAuth/useSanityAuth";

function RedaktørPanel() {
  const sanityAuth = useSanityAuth();
  const editInSanityContext = useEditInSanityContext();
  const previewContext = usePreviewContext();
  const { t } = useTranslation();

  // Antar at vi er i preview dersom vi er i en iframe. Dette for å få umiddelbar feedback på om vi er i preview eller ikke. Dette banneret ligger helt på toppen og skaper veldig tydelig hopping hvis det vises feil. Kan trygt bruke getInsideAnIframe() her fordi dette panelet uansett ikke rendres SSR og det skaper derfor ikke hydreringsproblemer.
  const show = sanityAuth.isAuthenticated && !previewContext.isOn && !getIsInsideAnIframe();

  return (
    <Box
      position="sticky"
      top={-1}
      zIndex={1000}
      boxShadow={show ? "md" : "none"}
      sx={{
        "@media print": { display: "none" },
      }}
    >
      <Collapse in={show} unmountOnExit>
        <Box backgroundColor={colors.statusYellowLight} opacity={0.925} color="gray.900">
          <HeaderFooterContainer
            padding="0.5rem 1rem .6rem"
            display="flex"
            flexWrap="wrap"
            gridGap="0.5rem 1rem"
            justifyContent="space-between"
            alignItems="center"
          >
            <HStack
              divider={
                <Box transform="translateY(-.1em)" border="none">
                  |
                </Box>
              }
            >
              <Heading as="h4" size="sm" display="flex" gridGap=".5rem" alignItems="center">
                {t("Redaktør")}
              </Heading>
              <Box>{sanityAuth.name}</Box>
            </HStack>
            <Link href="/cms/" onClick={() => logAdminClick("Gå til redaktørverktøy")}>
              {t("Gå til redaktørverktøy")}
            </Link>
            <Link
              display="flex"
              gap="0.5rem"
              alignItems="center"
              as="button"
              onClick={() => {
                logAdminClick(`${editInSanityContext.editMode ? "Skjul" : "Vis"} snarveier`);
                editInSanityContext.toggle();
              }}
            >
              <Icon flexShrink={0} as={EditPencil} transform="translateY(.1em)" />
              {editInSanityContext.editMode ? t("Skjul snarveier") : t("Vis snarveier")}
            </Link>
            <Link
              as="button"
              alignSelf="flex-end"
              onClick={() => {
                logAdminClick("Logg ut");
                sanityAuth.logOut();
              }}
            >
              {t("Logg ut")}
            </Link>
          </HeaderFooterContainer>
        </Box>
      </Collapse>
    </Box>
  );
}

export default RedaktørPanel;
