"use client";

import { ReactNode } from "react";
import CommonPageWrapper from "../../../../components/layout/CommonPageWrapper";
import { CommonData } from "../../../../components/layout/commonData";
import { SanityPreviewLiveQuery } from "../../../sanityFetcher/SanityPreviewLiveQuery";
import { SanityDataWithPreviewConfig } from "../../../sanityFetcher/getAppFolderPageProps";

type Props = {
  sanityData: SanityDataWithPreviewConfig<CommonData>;
  children: ReactNode;
};

export const ClientSideSiteLayout = (props: Props) => (
  <SanityPreviewLiveQuery sanityData={props.sanityData}>
    {(data) => <CommonPageWrapper commonData={data}>{props.children}</CommonPageWrapper>}
  </SanityPreviewLiveQuery>
);
