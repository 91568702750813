import { CSSWithMultiValues, RecursiveCSSSelector } from "@biblioteksentralen/react";

export const headerLinkDecorationStyle = (selected = false): RecursiveCSSSelector<CSSWithMultiValues> => ({
  textUnderlineOffset: "5px",
  textDecorationThickness: "2px",
  textDecorationLine: selected ? "underline" : "none",
  "&:hover": {
    textDecorationLine: "underline",
    textUnderlineOffset: "5px",
    textDecorationThickness: "2px",
  },
});
