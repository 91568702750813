import { Skeleton } from "@biblioteksentralen/react";
import { useRouter } from "next/navigation";
import { ChangeEventHandler, ComponentProps, FormEventHandler, Ref, Suspense, useState } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import { searchPagePath } from "../../hooks/useIsOnSearchPage";
import { useSearchConfig } from "../../hooks/useSearchConfig";
import { searchParameterName } from "../../searchApi/constants";
import { SearchInput } from "./SearchInput";

type HeaderSearchInputProps = {
  inputRef?: Ref<HTMLInputElement | null>;
  searchIsOpen?: boolean;
  mobileMenuIsOpen?: boolean;
} & Omit<ComponentProps<typeof SearchInput>, "onSubmit" | "value" | "onChange" | "clear">;

export const HeaderSearchInput = ({ inputRef, searchIsOpen, mobileMenuIsOpen, ...props }: HeaderSearchInputProps) => {
  const { push } = useRouter();
  const [inputValue, setInputValue] = useState("");
  const { isSearchIntegrationEnabled } = useSearchConfig();
  const { lang } = useTranslation();
  const paletteColors = useSitePalette().colors;

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => setInputValue(event.target.value);

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    if (isSearchIntegrationEnabled) {
      const params = new URLSearchParams({ [searchParameterName]: JSON.stringify(inputValue) });
      const path = `/${lang}${searchPagePath}?${params.toString()}`;

      push(path);
      return;
    }

    handleLegacyCatalogSearch(inputValue);
  };

  const clear = () => {
    setInputValue("");
  };

  return (
    <Suspense
      fallback={
        <Skeleton
          height="7rem"
          startColor={paletteColors.search.css.background}
          endColor={paletteColors.card.css.background}
        />
      }
    >
      <SearchInput
        onSubmit={onSubmit}
        value={inputValue}
        onChange={onChange}
        clear={clear}
        inputRef={inputRef}
        searchIsOpen={searchIsOpen}
        mobileMenuIsOpen={mobileMenuIsOpen}
        {...props}
      />
    </Suspense>
  );
};

const handleLegacyCatalogSearch = (query: string) => {
  window.location.assign(`/api/catalog-search?term=${encodeURIComponent(query)}`);
};
