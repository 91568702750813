import { useInView } from "framer-motion";
import { ReactNode, useRef } from "react";

// A component that mounts its children when it is in the viewport, can forexample be used for components with expensive rendering or fetching
export const MountWhenInViewport = (props: { children: ReactNode }) => {
  const ref = useRef<HTMLDivElement>(null);
  const hasBeenInViewport = useInView(ref, { once: true });

  return <div ref={ref}>{hasBeenInViewport ? props.children : null}</div>;
};
