/* eslint-disable i18next/no-literal-string */
"use client";

import { Box, Collapse, Flex, Heading, Link, ListItem, OrderedList, Stack } from "@biblioteksentralen/react";
import { ReactNode, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { SOMEPreview } from "../../../../../components/preview/SOMEPreview";
import { prettifyGroqQuery } from "@libry-content/common";
import { ShowForGlobalAdmins } from "../../../../../utils/ShowForGlobalAdmins";
import { SanityDataWithPreviewConfig } from "../../../../sanityFetcher/getAppFolderPageProps";
import { useDeveloperMetricsContext } from "./DeveloperMetricsProvider";
import { usePreviewContext } from "../../../../../components/preview/previewContext";
import { MountWhenInViewport } from "../../../../../components/MountWhenInViewport";
import { PageViews } from "./Pageviews";

export const DeveloperMetricsAppfolder = () => {
  const sanityDataEntries = useDeveloperMetricsContext().sanityDataEntries;
  const preview = usePreviewContext();

  if (preview.isOn) return null;

  return (
    <ShowForGlobalAdmins>
      <Stack padding="1rem" spacing="2rem">
        <Flex justify="space-between">
          <Box>
            <Heading as="h2" size="md" fontWeight={600}>
              Developer metrics
            </Heading>
            <Box>🚀 App-folder</Box>
          </Box>
          <MountWhenInViewport>
            <PageViews />
          </MountWhenInViewport>
        </Flex>

        <SOMEPreview marginY="2rem" />

        <Heading as="h3" size="sm">
          Sanity-data (SSR)
        </Heading>
        <OrderedList spacing="1em">
          {sanityDataEntries.map((entry) => (
            <SanityDataEntry key={entry._previewConfig?.query} entry={entry} />
          ))}
        </OrderedList>
      </Stack>
    </ShowForGlobalAdmins>
  );
};

const SanityDataEntry = ({ entry }: { entry: SanityDataWithPreviewConfig<object> }) => {
  return (
    <ListItem background="#8882" padding=".25em">
      <Stack spacing="2rem">
        <Metric
          label="Query"
          value={
            <CollapsableCode code={entry._previewConfig?.query && prettifyGroqQuery(entry._previewConfig?.query)} />
          }
        />
        <Metric
          label="Params"
          value={
            <CollapsableCode
              code={entry._previewConfig?.queryParams && JSON.stringify(entry._previewConfig?.queryParams, null, 2)}
            />
          }
        />
        <Metric
          label="Data"
          value={
            <Flex gap="1rem">
              <Box>{`${(JSON.stringify(entry._raw_data).length / 1028).toFixed()} kb`}</Box>
              <Link
                alignSelf="flex-start"
                fontWeight={600}
                as="button"
                onClick={() =>
                  console.log({
                    data: entry._raw_data,
                    dataSize: entry._raw_data && sizeOfEntries(entry._raw_data),
                  })
                }
              >
                Print to console
              </Link>
            </Flex>
          }
        />
      </Stack>
    </ListItem>
  );
};

const Metric = ({ label, value }: { label: string; value: ReactNode }) => (
  <Box display="flex" gap="1rem">
    <Box minW="5rem">{label}</Box>
    <Box fontWeight={600} minW="10rem">
      {value}
    </Box>
  </Box>
);

const CollapsableCode = (props: { code?: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box>
      <Collapse startingHeight="2.5em" in={isOpen}>
        <Box as="pre" fontWeight="normal" background="#3338" color="white" padding=".25em" overflow="auto">
          {props.code}
        </Box>
      </Collapse>
      <Link
        fontWeight={600}
        display="flex"
        gap=".5em"
        alignItems="center"
        as="button"
        variant="tertiary"
        onClick={() => setIsOpen((s) => !s)}
      >
        {isOpen ? "Vis mindre" : "Vis mer"} {isOpen ? <ChevronUp /> : <ChevronDown />}
      </Link>
    </Box>
  );
};

type SizeOfEntries = {
  [key: string]: {
    sizeKB: number;
    entries: SizeOfEntries;
  };
};

const sizeOfEntries = (object: object): SizeOfEntries => {
  if (!object || typeof object !== "object") return {};

  return Object.entries(object).reduce(
    (acc, [label, data]) => ({
      ...acc,
      [label]: {
        sizeKB: Number((JSON.stringify(data).length / 1024).toFixed(1)),
        entries: sizeOfEntries(data),
      },
    }),
    {}
  );
};
