import { Box, HStack, Image, Link, StackProps } from "@biblioteksentralen/react";
import { Modify } from "@biblioteksentralen/types";
import { imageUrlBuilder } from "@libry-content/common";
import { Municipality } from "@libry-content/types";
import { useCommonData } from "../CommonDataProvider";

type MunicipalityWithCoatOfArms = Modify<Municipality, Required<Pick<Municipality, "coatOfArms">>>;

const hasCoatOfArms = (municipality?: Municipality): municipality is MunicipalityWithCoatOfArms =>
  typeof municipality === "object" && !!municipality?.["coatOfArms"]?.asset;

function CoatOfArms(props: StackProps) {
  const { site } = useCommonData();
  if (!hasCoatOfArms(site?.municipality)) return null;

  const additionalMunicipalities = site?.hasAdditionalMunicipalities ? site.additionalMunicipalities ?? [] : [];
  const municipalities = [site?.municipality, ...additionalMunicipalities].filter(hasCoatOfArms);

  return (
    <HStack alignItems="center" justifyContent="flex-end" gap="1.5rem" flexWrap="wrap" {...props}>
      {municipalities.map(({ name, coatOfArms, url }) => {
        const LinkOrBox = url ? Link : Box;
        const linkProps = url ? { href: url, variant: "plain" } : {};
        return (
          <LinkOrBox key={name} display="flex" alignItems="center" gap="0.5rem" marginTop="0 !important" {...linkProps}>
            <Image
              alt=""
              height={{ base: "3rem", sm: "2.5rem" }}
              src={imageUrlBuilder(coatOfArms)?.height(75).url() ?? ""}
            />
            {name && (
              <Box style={{ hyphens: "none" }} width="min-content" lineHeight="1.2" fontSize={{ base: "md", sm: "sm" }}>
                <span translate="no">{name}</span>
              </Box>
            )}
          </LinkOrBox>
        );
      })}
    </HStack>
  );
}

export default CoatOfArms;
