"use client";
import { usePathname } from "next/navigation";

export const searchPagePath = "/sok";

// Sjekker at pathen er eksakt (/sok) eller med query params (eks /sok?s="Hello world"). Hvis vi ikke gjør det kan vi få false positives, som feks at /sokndal blir matchet, som skapte mye trøbbel i pipeline.
const searchPageRegex = /\/sok(\?|$)/;

export const useIsOnSearchPage = () => {
  const pathname = usePathname();
  return !!pathname?.match(searchPageRegex);
};
