import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useCommonData } from "../CommonDataProvider";

type NavigationLinkConfig = { title: string; path: string };

export const useHeaderNavigationLinks = (): NavigationLinkConfig[] => {
  const { headerFooterData } = useCommonData();
  const { t } = useTranslation();

  if (!headerFooterData) return [];

  const harLeseforslag = !!headerFooterData.bookListCount || !!headerFooterData.recommendationCount;

  return [
    ...(harLeseforslag ? [{ path: "/lister", title: t("Leseforslag") }] : []),
    ...(headerFooterData.eventsCount > 0 ? [{ path: "/arrangementer", title: t("Arrangementer") }] : []),
    ...(headerFooterData.digitalLibraryServiceCount > 0
      ? [{ path: "/digitalt-bibliotek", title: t("Digitalt bibliotek") }]
      : []),
    ...(headerFooterData.servicesCount > 0 ? [{ path: "/tjenester", title: t("Tjenester og tilbud") }] : []),
  ];
};
