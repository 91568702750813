import { useCallback, useEffect, useState } from "react";

/**
 * When StatusTracker updates we assume that the load is finished.
 */
type StatusTracker = number | string | boolean;

/**
 * Used to give immediate feedback to the user on clicking load, updates when loading is finished.
 */
export const useOnClickLoad = (statusTracker: StatusTracker) => {
  const [statusTrackerWhenClicked, setStatusTrackerWhenClicked] = useState<StatusTracker | undefined>(undefined);

  const onClickLoad = useCallback(() => setStatusTrackerWhenClicked(statusTracker), [statusTracker]);

  const isLoading = typeof statusTrackerWhenClicked !== "undefined";

  useEffect(() => {
    if (isLoading && statusTracker !== statusTrackerWhenClicked) {
      setStatusTrackerWhenClicked(undefined);
    }
  }, [statusTracker, isLoading, statusTrackerWhenClicked]);

  return { onClickLoad, isLoading };
};
