import {
  Box,
  ChakraProps,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  colors,
  useDisclosure,
} from "@biblioteksentralen/react";
import { MouseEvent, useEffect, useRef } from "react";
import { Menu } from "react-feather";
import { HeaderSearchInput } from "../../../searchAndCollection/components/search/HeaderSearchInput";
import { SearchViewInput } from "../../../searchAndCollection/components/search/SearchViewInput";
import { useIsOnSearchPage } from "../../../searchAndCollection/hooks/useIsOnSearchPage";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import InternalLink from "../../InternalLink";
import { FooterMenuMobile } from "../footer/FooterMenu";
import { HeaderUserInfo } from "./HeaderUserInfo";
import Logo from "./Logo";
import { MinSideLink } from "./MinSideLink";
import { useHeaderNavigationLinks } from "./useHeaderNavigationLinks";
import { usePathname } from "next/navigation";

const hoverStyles: ChakraProps = { _hover: { backgroundColor: "rgba(0,0,0,.05)", textDecoration: "none" } };

export const Mobile = () => {
  const { t } = useTranslation();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const navigationLinks = useHeaderNavigationLinks();
  const buttonRef = useRef(null);
  const isSearchPage = useIsOnSearchPage();
  const palette = useSitePalette();
  const pathName = usePathname();

  // Lukke mobilmeny når man navigerer til ny side, feks når man gjør et søk
  useEffect(() => {
    onClose();
  }, [pathName, onClose]);

  // Lukker automatisk mobil-menyen hvis brukeren prøver å navigere til siden de allerede er på
  const closeMenuWhenSamePathname = (event: MouseEvent) => {
    const pathname = (event.target as EventTarget & HTMLElement).getAttribute("href");
    if (pathname === location.pathname) onClose();
  };

  return (
    <Box as="nav">
      <Flex padding={{ base: ".5rem .75rem" }} justifyContent="space-between" alignItems="center">
        <Logo />
        <IconButton
          onClick={onToggle}
          icon={<Menu strokeWidth={2.5} />}
          aria-label={t("Meny")}
          aria-expanded={isOpen}
          variant="ghost"
          fontSize="1.4rem"
          color="inherit"
          ref={buttonRef}
        />
      </Flex>
      <Drawer isOpen={isOpen} onClose={onClose} finalFocusRef={buttonRef} size="full" placement="right">
        <DrawerOverlay />
        <DrawerContent overflow="auto" onClick={closeMenuWhenSamePathname}>
          <DrawerHeader fontWeight="unset" {...palette.colors.header.css}>
            <Flex justifyContent="space-between" alignItems="center">
              <Logo />
              <DrawerCloseButton position="unset" aria-label={t("Lukk meny")} />
            </Flex>
          </DrawerHeader>
          {!isSearchPage && (
            <HeaderSearchInput id="catalogSearchMobileHeader" onAfterSubmit={onClose} mobileMenuIsOpen={isOpen} />
          )}
          <Box as="ul" listStyleType="none" paddingY={isSearchPage ? "0" : "1rem"}>
            {navigationLinks.map((link) => (
              <Box as="li" key={link.path}>
                <InternalLink
                  href={link.path}
                  display="block"
                  fontSize="lg"
                  padding=".75rem 1rem"
                  variant="plain"
                  whiteSpace="nowrap"
                  {...hoverStyles}
                >
                  {link.title}
                </InternalLink>
              </Box>
            ))}
          </Box>
          <Box borderY={`solid 1px ${colors.grey10}`} flexDirection="column">
            <Flex>
              <MinSideLink padding="1.5rem 1rem" justifyContent="flex-start" flexBasis="100%" {...hoverStyles} />
            </Flex>
            <HeaderUserInfo padding="0 1rem .75rem" />
          </Box>
          <Box padding="1rem 0 3rem">
            <FooterMenuMobile />
          </Box>
        </DrawerContent>
      </Drawer>
      {isSearchPage && <SearchViewInput id="catalogSearchMobile" />}
    </Box>
  );
};
