import { Alert, Box, Flex, Heading, Link, Spinner, Text } from "@biblioteksentralen/react";
import { getPlausibleDashboardUrl, isProduction, jsonFetcher } from "@libry-content/common";
import { PageStatsResponse, isApiError } from "@libry-content/types";
import { usePathname } from "next/navigation";
import useSWR from "swr";
import { useCommonData } from "../../../../../components/layout/CommonDataProvider";

/* eslint-disable i18next/no-literal-string */
export const PageViews = () => {
  const path = usePathname();
  const site = useCommonData().site;
  const { error, data } = usePageViews(path);

  if (error || isApiError(data)) {
    isProduction && site?.isLive && console.error(error, data);
    return (
      <Alert status="warning" variant="inline">
        Kunne ikke hente sidevisninger
      </Alert>
    );
  }
  if (!data) return <Spinner />;

  return (
    <div>
      <Flex gap=".5rem" alignItems="baseline">
        <Heading size="sm" as="h3">
          Sidevisninger
        </Heading>
        <Text fontSize="xs">siste 6 måneder</Text>
      </Flex>
      <Box>
        <b>{data.pageviews.value}</b> visninger / <b>{data.visits.value}</b> besøk / <b>{data.visitors.value}</b> unike
        brukere
      </Box>
      {site && <Link href={getPlausibleDashboardUrl(site)}>Gå til plausible</Link>}
    </div>
  );
};

const usePageViews = (path: string | null) =>
  useSWR<PageStatsResponse>(path ? `/api/internal/metrics/path?path=${path}` : null, jsonFetcher);
