import { Button, ChakraProps, Link } from "@biblioteksentralen/react";
import { User } from "react-feather";
import { useLibrarySystemClient } from "../../../utils/hooks/useLibrarySystemClient";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { LocalizedNextLink } from "../../LocalizedNextLink";
import { useCommonData } from "../CommonDataProvider";
import { headerLinkDecorationStyle } from "../utils";

export const MinSideLink = (chakraProps: ChakraProps) => {
  const librarySystem = useLibrarySystemClient();
  const rediaCustomerId = useCommonData().site?.librarySystem?.rediaPlatformConfig?.customerId;
  const isBibliofil = librarySystem?.config.type === "bibliofil";
  const brukLCMinSide = rediaCustomerId && isBibliofil;
  const minSideUrl = brukLCMinSide ? "/min-side" : librarySystem?.getLinkToMyAccount();
  const { t } = useTranslation();

  if (!minSideUrl) return null;

  return (
    <Button
      as={brukLCMinSide ? LocalizedNextLink : Link}
      leftIcon={<User size="1.1em" strokeWidth={2.2} />}
      variant="plain"
      sx={headerLinkDecorationStyle()}
      href={minSideUrl}
      {...chakraProps}
    >
      {t("Min side")}
    </Button>
  );
};
