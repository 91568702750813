import { Button } from "@biblioteksentralen/react";
import React from "react";

type Props = {
  title: string;
  focusOnClick: () => void;
};

const SkipNavButton = ({ title, focusOnClick }: Props) => {
  return (
    <Button
      size="md"
      zIndex="999"
      position="absolute"
      transform="translateX(-100%) translateY(.5rem)"
      _focus={{ transform: "translateX(.5rem) translateY(.5rem)" }}
      onClick={focusOnClick}
    >
      {title}
    </Button>
  );
};

export default SkipNavButton;
