import { getPath } from "../../../utils/getPath";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import InternalLink from "../../InternalLink";
import { HeaderFooterData } from "../sanityQuery";
import { FooterMenuList } from "./FooterMenuList";

export const ContactFooterMenuItems = ({ headerFooterData }: { headerFooterData: HeaderFooterData }) => {
  const { t } = useTranslation();
  const moreThanOneLibrary = headerFooterData.libraries.length > 1;
  const firstLibrary = headerFooterData.libraries[0];

  return (
    <FooterMenuList>
      {firstLibrary && (
        <li>
          <InternalLink href={moreThanOneLibrary ? "/bibliotek" : getPath(firstLibrary)} variant="plain">
            {moreThanOneLibrary ? t("Våre bibliotek") : t("Om biblioteket")}
          </InternalLink>
        </li>
      )}
      {headerFooterData.employeeCount > 0 && (
        <li>
          <InternalLink href={"/ansatte"} variant="plain">
            {t("Ansatte")}
          </InternalLink>
        </li>
      )}
    </FooterMenuList>
  );
};
