import { Box, Text } from "@biblioteksentralen/react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useCommonData } from "../CommonDataProvider";
import { headerLinkDecorationStyle } from "../utils";
import { getWeightedSiteNameParts } from "@libry-content/common";

export const FormattedSiteName = () => {
  const { ts } = useTranslation();
  const { site } = useCommonData();

  const siteName = ts(site?.name)?.trim();
  const siteNameParts = getWeightedSiteNameParts(siteName);

  return (
    <Text
      textTransform="uppercase"
      _groupHover={{ textDecorationLine: "underline" }}
      lineHeight="1"
      transform="translateY(.1em)"
      fontSize={{ base: "md", sm: "lg", lg: "xl" }}
      sx={headerLinkDecorationStyle()}
      display="inline-flex"
      flexWrap="wrap"
    >
      {siteNameParts.map(({ value, bold }, index) => (
        <Box
          as="span"
          key={index}
          fontWeight={bold ? 600 : undefined}
          marginRight={value.endsWith(" ") ? ".2em" : undefined}
        >
          {value}
        </Box>
      ))}
    </Text>
  );
};
