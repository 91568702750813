import { Url } from "next/dist/shared/lib/router/router";
import { z } from "zod";
import { useMultipleUrlStates } from "./useMultipleUrlStates/useMultipleUrlStates";

type State = string | number;

export function useUrlState<T extends State>(
  initialState: T,
  key: string
): [T, (state: T | undefined) => void, (state: T | undefined) => Url] {
  const zodSchema = typeof initialState === "string" ? z.string() : z.number();
  const [state, updateState, getUrlToNextState] = useMultipleUrlStates(z.object({ [key]: zodSchema }));

  const stateOrInitial = (state?.[key] as T) ?? initialState;
  const setState = (state: T | undefined) => updateState({ [key]: state });
  const getUrlToNewState = (state: T | undefined) => getUrlToNextState({ [key]: state });

  return [stateOrInitial, setState, getUrlToNewState];
}
