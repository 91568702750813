import { ChangeEventHandler, ComponentProps, FormEventHandler, useState } from "react";
import { SearchInput } from "./SearchInput";
import { useSearchState } from "./useSearchState";
import { useMount } from "react-use";

type SearchViewInputProps = Omit<ComponentProps<typeof SearchInput>, "onSubmit" | "value" | "onChange" | "clear">;

export const SearchViewInput = (props: SearchViewInputProps) => {
  const [inputValue, setInputValue] = useState("");
  const [searchState, setSearchState] = useSearchState();

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setInputValue(event.target.value);
  };

  const onSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    setSearchState({
      s: inputValue,
      selectedCategory: undefined,
      work: undefined,
      agent: undefined,
      yourLibrary: undefined,
    });
  };

  const clear = () => {
    setInputValue("");
  };

  // Update input value when mounting page with a defined searchQuery (i.e. .../sok?s=<searchQuery>)

  useMount(() => {
    searchState?.s && setInputValue(searchState.s);
  });

  return <SearchInput value={inputValue} onChange={onChange} onSubmit={onSubmit} clear={clear} {...props} />;
};
