import { Box, Link } from "@biblioteksentralen/react";
import { ExternalLink } from "react-feather";
import { getPath } from "../../../utils/getPath";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { isUrlInternal } from "../../../utils/isUrlInternal";
import InternalLink from "../../InternalLink";
import { useCommonData } from "../CommonDataProvider";
import { FooterMenuItem, ResolvedFooterMenu } from "../sanityQuery";
import { FooterMenuList } from "./FooterMenuList";

export const menuItemHasContent = (menuItem?: FooterMenuItem) =>
  typeof menuItem?._type === "string" && (menuItem?._type !== "externalLink" || menuItem.url);

const getKey = (item: FooterMenuItem): string => ("_key" in item ? item._key : item._id);

export const FooterMenuItems = (props: { footerMenu: ResolvedFooterMenu }) => {
  const { ts } = useTranslation();
  const { site } = useCommonData();

  return (
    <FooterMenuList>
      {props.footerMenu.menuItems?.filter(menuItemHasContent).map((menuItem: FooterMenuItem) => (
        <Box as="li" key={getKey(menuItem)} whiteSpace="normal">
          {menuItem._type === "externalLink" && (
            <Link href={menuItem.url!} variant="plain" alignItems="center" display="inline-flex" gap=".3rem">
              {ts(menuItem?.title)}
              {!isUrlInternal(site, menuItem.url) && <ExternalLink size="1em" />}
            </Link>
          )}
          {menuItem._type === "staticPage" && (
            <InternalLink href={getPath(menuItem)} variant="plain">
              {ts(menuItem?.title)}
            </InternalLink>
          )}
        </Box>
      ))}
    </FooterMenuList>
  );
};
