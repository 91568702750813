import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionItemProps,
  AccordionPanel,
  Box,
  BoxProps,
  Grid,
  Heading,
  VStack,
  usePrefersReducedMotion,
} from "@biblioteksentralen/react";
import { ReactNode } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { Edit } from "../../editInSanity/EditInSanity";
import { useCommonData } from "../CommonDataProvider";
import { ResolvedFooterMenu } from "../sanityQuery";
import { ContactFooterMenuItems } from "./ContactFooterMenuItems";
import { FooterMenuItems, menuItemHasContent } from "./FooterMenuItems";
import { SiteFooterMenuItems } from "./SiteFooterMenuItems";

const footerMenuHasContent = ({ menuItems }: ResolvedFooterMenu) => !!menuItems?.filter(menuItemHasContent).length;

export const FooterMenu = (chakraProps: BoxProps) => {
  const { headerFooterData } = useCommonData();
  if (!headerFooterData) return null;

  return (
    <Box as="section" {...chakraProps}>
      <Box display={{ lg: "none" }}>
        <FooterMenuMobile />
      </Box>
      <Box display={{ base: "none", lg: "initial" }}>
        <FooterMenuDesktop />
      </Box>
      {headerFooterData.footerMenuCollection && <Edit doc={headerFooterData.footerMenuCollection} />}
    </Box>
  );
};

const FooterMenuDesktop = () => {
  const { t, ts } = useTranslation();
  const { headerFooterData } = useCommonData();
  if (!headerFooterData) return null;

  const { secondMenu, thirdMenu } = headerFooterData.footerMenuCollection ?? {};

  return (
    <Grid gridTemplateColumns="repeat(auto-fill, minmax(15rem, 1fr))" whiteSpace="nowrap" gap="2rem">
      <VStack alignItems="start" marginInlineStart="0!important">
        <Heading as="h2" marginBottom=".5rem" size="1rem">
          {t("Kontakt")}
        </Heading>
        <ContactFooterMenuItems headerFooterData={headerFooterData} />
      </VStack>
      {!!secondMenu && footerMenuHasContent(secondMenu) && (
        <VStack alignItems="start" marginInlineStart="0!important">
          <Heading as="h2" marginBottom=".5rem" size="1rem">
            {ts(secondMenu?.menuHeader)}
          </Heading>
          <FooterMenuItems footerMenu={secondMenu} />
        </VStack>
      )}
      {!!thirdMenu && footerMenuHasContent(thirdMenu) && (
        <VStack alignItems="start" marginInlineStart="0!important">
          <Heading as="h2" marginBottom=".5rem" size="1rem">
            {ts(thirdMenu?.menuHeader)}
          </Heading>
          <FooterMenuItems footerMenu={thirdMenu} />
        </VStack>
      )}
      <VStack alignItems="start" marginInlineStart="0!important">
        <Heading as="h2" marginBottom=".5rem" size="1rem">
          {t("Nettstedet")}
        </Heading>
        <SiteFooterMenuItems />
      </VStack>
    </Grid>
  );
};

export const FooterMenuMobile = () => {
  const { t, ts } = useTranslation();
  const { headerFooterData } = useCommonData();
  const prefersReducedMotion = usePrefersReducedMotion();
  if (!headerFooterData) return null;

  const { secondMenu, thirdMenu } = headerFooterData.footerMenuCollection ?? {};

  return (
    <Accordion as="ul" listStyleType="none" width="100%" reduceMotion={prefersReducedMotion} allowMultiple>
      <FooterAccordionItem title={t("Kontakt")}>
        <ContactFooterMenuItems headerFooterData={headerFooterData} />
      </FooterAccordionItem>
      {!!secondMenu && footerMenuHasContent(secondMenu) && (
        <FooterAccordionItem title={ts(secondMenu?.menuHeader)}>
          <FooterMenuItems footerMenu={secondMenu} />
        </FooterAccordionItem>
      )}
      {!!thirdMenu && footerMenuHasContent(thirdMenu) && (
        <FooterAccordionItem title={ts(thirdMenu?.menuHeader)}>
          <FooterMenuItems footerMenu={thirdMenu} />
        </FooterAccordionItem>
      )}
      <FooterAccordionItem title={t("Nettstedet")}>
        <SiteFooterMenuItems />
      </FooterAccordionItem>
    </Accordion>
  );
};

const FooterAccordionItem = ({
  title,
  children,
  ...chakraProps
}: { title?: string; children: ReactNode } & AccordionItemProps) => (
  <AccordionItem as="li" border="none" {...chakraProps}>
    <AccordionButton padding=".5rem .75rem" justifyContent="space-between">
      <Heading as="h2" marginY=".25rem" size="1rem" fontWeight={600}>
        {title}
      </Heading>
      <AccordionIcon />
    </AccordionButton>
    <AccordionPanel padding="0 1rem 1rem">{children}</AccordionPanel>
  </AccordionItem>
);
